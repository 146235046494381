import * as React from "react";
import { useEffect } from "react";
import Topbar from "../components/Topbar";
import FootView from "../views/FootView";

const UserPolicyPage = (props: any) => {
  const ref = React.createRef();

  useEffect(() => {
    window.location.href = "https://www.saluto.ai";
  }, []);

  return <></>;

  return (
    <div className="w-screen overflow-hidden">
      <Topbar pathName={props.location.pathname}></Topbar>
      <div className="w-4/5 md:w-2/3 mx-auto">
        <br />
        <h1 className="text-2xl">Användarvillkor</h1>
        <p>
          Inference Labs (hädanefter ”vi”, ”vår” eller ”oss”) erbjuder
          hälsokontroller med blodprovsanalys till privatpersoner och företag
          under produktnamnet Saluto.
        </p>
        <br />
        <p>
          Mobilapplikationen Saluto (hädanefter ”Saluto”) är en hälsoplattform
          som sammanför en stor bredd av olika hälsodata och tar fram
          individuellt anpassade råd för de livsstilsförändringar som skulle ha
          störst positiv effekt på din hälsa. Detta genom att kombinera data och
          uppgifter från tagna blodprover, kroppsmått, hälsoenkäter, digital
          livsstilslogg samt andra relevanta uppgifter som du tillhandahåller
          uppnås en helhetsbild av din hälsa med fördjupade insikter kring
          eventuella hälsorisker.
        </p>
        <br />
        <p>
          Vi kan dock inte ställa diagnos eller fastställa om du är sjuk eller
          frisk och hälsokontrollen ska således inte förväxlas eller ersätta
          sedvanlig medicinsk vård eller sjukvårdsrådgivning. Saluto kan inte
          betraktas som ett substitut till traditionell sjukvård utan det utgör
          endast ett komplement. Vidare finns det möjlighet att ditt resultat av
          olika anledningar är missvisande eller att de prov som tagits inte ger
          en rättvisande bild av situationen då alla kan reagera olika på de råd
          som ges. Resultaten från analysen kan också vara missvisande av andra
          skäl exempelvis om du inte har följt de anvisningar du får avseende
          provtagningen eller att du inte har lagt in nödvändiga uppgifter i
          Saluto.
        </p>
        <br />
        <p>
          Våra hälsokontroller med blodprovsanalys ersätter inte
          läkarkonsultation. Inference Labs har inte ett vårdande ansvar. Det
          åligger ditt eget ansvar att fortsätta hålla regelbunden kontakt med
          din vanliga vårdgivare gällande din hälsa. Om du upplever att du kan
          vara i behov av att träffa en läkare, är orolig för din hälsa eller om
          du är sjuk ska du uppsöka sjukvård på samma grund som om du ej haft
          någon information via tester gjorda med oss. Vårdguiden (www.1177.se)
          finns även för dig som vill söka tips och råd. Vid akut sjukdom ring
          alltid 112.
        </p>
        <br />
        <p>
          Vi är registrerade som vårdgivare hos IVO, Inspektionen för Vård och
          Omsorg. Till dem kan du anmäla om du har några synpunkter eller
          klagomål.
        </p>
        <br />
        <p>
          Våra tjänster riktar sig till privatpersoner som fyllt 18 år och
          företag som beställer hälsoanalyser till sina anställda genom
          prenumeration. För att kunna använda Saluto behöver du ha BankID, ett
          tolvsiffrigt (12) svenskt personnummer samt ett e-postkonto för att vi
          ska kunna hantera remisser, notiser mm.
        </p>
        <p>
          Vid godkännande av dessa villkor ger du oss tillstånd till att
          behandla dina personuppgifter i enlighet med dataskyddsförordningen
          (EU) 2016/679 (hädanefter ”GDPR”). Vi är personuppgiftsansvariga för
          behandlingen av dina personuppgifter på hemsidan samt i Saluto, i den
          mån vi inte bedöms agera personuppgiftsbiträde åt din arbetsgivare i
          tillämpliga fall, och vi vidtar åtgärder för att skydda dessa
          uppgifter i enlighet med GDPR.
        </p>
        <br />
        <h1 className="text-2xl">Villkor för registrering</h1>
        <p>
          Genom att klicka på “Slutför registrering” accepterar du dessa villkor
          och du lämnar även ditt samtycke till att vi får behandla, lagra och
          analysera de personuppgifter och prover som du lämnar genom Saluto i
          enlighet med dessa Användarvillkor samt vår Integritetspolicy.
        </p>
        <br />
        <p>
          Dina lämnade personuppgifter kommer även att användas vid utskick av
          nyhetsbrev och i samband med påminnelser t.ex. vid blodprov,
          fakturering och annan marknadsföring som t.ex. remarketing.
        </p>
        <br />
        <p>
          I den mån samtycke har lämnats, får vi även behandla, lagra och
          analysera;
        </p>
        <br />
        <p>
          (a) dina tidigare provsvar med därtill hörande journalanteckningar hos
          andra vårdgivare i den mån det är möjligt och finns tillgängligt för
          att hälsoanalysen och de anpassade råden ska bli så heltäckande och
          komplett som möjligt; och
        </p>
        <p>
          (b) din användardata och andra personuppgifter som du tillhandahåller
          i pseudonymiserad, för vidareutveckling av Saluto och dess tjänster
          samt för annat forskningsunderlag eller till statistik.
        </p>
        <br />
        <p>
          Du kan närsomhelst dra tillbaka ditt samtycke genom att mejla oss på
          support@saluto.ai. Du är införstådd med att ett återkallat samtycke
          kan leda till att våra tjänster inte kan fortsätta att tillhandahållas
          eller att hälsoanalysen och de anpassade råden kan bli missvisande.
          För mer information vänligen läs vår Integritetspolicy gällande vår
          behandling.
        </p>
        <br />
        <p>
          Vi äger även rätt att erhålla sådan information om din användning av
          Saluto som, enligt vår skäliga bedömning, krävs för att uppfylla vid
          var tid gällande lagar, förordningar och myndighetskrav.
        </p>
        <br />
        <p>
          Du bekräftar även att du har rätt att fullfölja registreringen, och
          att du är en fysisk person över 18 år med ett svenskt personnummer. Du
          bekräftar vidare att de uppgifter du lämnar är korrekta och inte
          tillhör någon annan. För att få tillgång till Saluto behöver du
          identifiera dig med BankID, eller annan identifieringstjänst som vi
          från tid till annan använder.
        </p>
        <br />
        <p>
          Våra integrationer med HealthKit (applikationen Hälsa) och Google Fit
          (applikationen Google Fit) gör det till möjligt för dig att som
          användare kunna importera data såsom exempelvis stegräknardata, till
          Saluto från applikationerna Hälsa eller Google Fitness. I
          applikationerna Hälsa och Google Fit styr du vilka data som du vill
          synkronisera med Saluto och du kan när som helst välja att koppla från
          när du inte längre vill dela din data från applikationen Hälsa eller
          Google Fit med Saluto. I den mån du väljer att synkronisera din data
          med Saluto lämnar du ditt samtycke till att vi får behandla dina
          personuppgifter i enlighet med dessa Användarvillkor samt vår
          Integritetspolicy. Vi kommer bara behandla de personuppgifter som
          kommer vara relevanta för tillhandahållandet av våra tjänster och för
          att kunna ge dig mer individuellt anpassade råd. Dessa personuppgifter
          kommer aldrig användas för marknadsföring eller liknande tjänster.
        </p>
        <br />
        <p>
          Du får inte använda Saluto eller dess tjänster för att göra eller dela
          något:
        </p>
        <br />
        <p>
          (a) som bryter mot de här Användarvillkoren, andra villkor och
          riktlinjer som gäller för din användning av Saluto;
          <br />
          (b) som är olagligt, vilseledande, diskriminerande eller bedrägligt,
          <br />
          (c) som gör intrång i eller strider mot Inference Labs eller någon
          annans rättigheter, inklusive deras immateriella rättigheter.
        </p>
        <p>
          Du får inte ladda upp virus eller skadlig kod eller göra något som kan
          inaktivera, överbelasta eller hämma Salutos korrekta funktion eller
          karaktär.
        </p>
        <br />
        <p>
          Du får inte söka åtkomst till eller samla in data från Saluto och dess
          tjänster genom att använda automatiserade medel (utan vårt föregående
          medgivande) eller försöka få åtkomst till data som du inte har
          behörighet för. Ditt konto kan spärras permanent om du laddar upp
          oseriöst, kränkande eller olagligt innehåll, sprider
          upphovsrättsskyddat material vidare eller på annat sätt skadar
          Inference Labs och dess rättigheter (inklusive våra underleverantörers
          rättigheter).
        </p>
        <br />
        <p>
          Vi förbehåller oss alltid rätten att kunna ensidigt bestämma vem som
          ska ges tillgång till Saluto och dess tjänster och vi kan således neka
          eller avsluta en prenumeration eller spärra ett konto utan angivande
          av skäl. Återbetalning av eventuell prenumerationsavgift sker för
          återstående och outnyttjad prenumerationsperiod såvida det inte rör
          sig om ett brott mot dessa Användarvillkor.
        </p>
        <br />
        <h1 className="text-2xl">Leverans av hälso- och livsstilsrapporter</h1>
        <p>
          För leverans av hälsorapport så kommer du i samband med din
          registrering bli ombedd att välja ett provtagningsställe för
          blodprovstagning samt dela med dig av uppgifter kopplade bland annat
          till din fysiska och/eller mentala hälsa. Detta gör du främst genom
          att fylla i en hälsoenkät. Dessa uppgifter kan omfatta, men är inte
          begränsade till, information om vikt, längd, om du lider av någon
          sjukdom, din medicinska historia eller ditt fysiologiska eller
          biomedicinska tillstånd. I det fall samtycke lämnats kommer vi även
          inhämta dina tidigare provsvar med därtill hörande journalanteckningar
          hos andra vårdgivare i den mån det är möjligt och finns tillgängligt.
          Förutsättningen för att du ska erhålla en hälsorapport är att du,
          utöver ifyllandet av hälsoenkäten, också lämnar blodprov.
        </p>
        <br />
        <p>
          Du kan få lämna ett blodprov vid flera olika tillfällen under
          prenumerationen. För leverans av en uppföljande hälsorapport kan du
          exempelvis bli ombedd att ta ett nytt blodprov.
        </p>
        <br />
        <p>
          För leverans av livsstilsrapporter kommer du behöva att löpande lägga
          in uppgifter om din livsstil och allmänna hälsotillstånd genom att
          föra en s.k. livsstilslogg. Uppgifter rörande din kost, motion, sömn
          och ditt allmänna mående kommer därför kontinuerligt att efterfrågas
          och loggas. Förutsättningen för att du ska erhålla en livsstilsrapport
          som tar höjd för dina livsstilsfaktorer är att du tillhandahåller
          dessa uppgifter genom en kontinuerlig loggning av nödvändig data i
          Saluto.
        </p>
        <br />
        <p>
          För att du ska erhålla hälso- och livsstilsrapporter är det därför
          viktigt att du som användare tillhandahåller nödvändiga uppgifter som
          efterfrågas via Saluto samt att du lämnar blodprov vid behov. Du
          kommer alltid att bli meddelad när du behöver komplettera med nya
          uppgifter eller när du måste ta nya blodprover.
        </p>
        <br />
        <h1 className="text-2xl">Leverans av hälso- och livsstilsrapporter</h1>
        <p>
          Användning av Saluto sker genom att man registrerar sig för en
          prenumeration som kan antingen löpa på 6 månader eller 12 månader.
        </p>
        <br />
        <p>
          Vid registrering i egenskap av anställd, tillämpas den
          prenumerationsperiod som din arbetsgivare har valt och din
          prenumeration avslutas i enlighet med din arbetsgivares uppsägning.
        </p>
        <br />
        <p>
          Vid registrering i egenskap av privatperson, ska din uppsägning av
          prenumerationen ske senast en månad innan den initiala
          prenumerationens slut. I avsaknad av sådan uppsägning förlängs
          prenumerationen med 6 månader i taget med samma uppsägningstid. I
          egenskap av privatperson har du i vissa fall rätt till ångerrätt
          enligt villkoren nedan.
        </p>
        <br />
        <h1 className="text-2xl">Fakturabetalning</h1>
        <p>
          Prenumerationsavgiften för den valda prenumerationsperioden (antingen
          6 månaders eller 12 månaders prenumeration) faktureras i förskott med
          ett betalningsvillkor på 30 dagar i samband med din registrering. Ny
          fakturering sker i samband med en förlängning.
        </p>
        <br />
        <p>
          Om du registrerar dig i egenskap av privatperson kommer faktura
          skickas till den adress som angivits i samband med registreringen.
        </p>
        <br />
        <p>
          Vid registrering i egenskap av anställd sker fakturering enligt
          överenskommelse med din arbetsgivare.
        </p>
        <br />
        <h1 className="text-2xl">Försenad betalning</h1>
        <p>
          Några dagar efter fakturans förfallodag skickar vi en påminnelse. Vid
          upprepade förseningar skickar vi inga fler påminnelser. Vid försenad
          betalning utgår även dröjsmålsränta enligt räntelagen som är
          Riksbankens referensränta på + 8 procentenheter. Dessutom påförs en
          påminnelseavgift. Avgiften är lagstadgad och utgår med 60 kronor.
        </p>
        <br />
        <h1 className="text-2xl">Ångerrätt för privatpersoner</h1>
        <p>
          Som privatperson har du rätt att frånträda detta avtal utan att ange
          något skäl inom 14 dagar. Ångerfristen löper ut 14 dagar efter
          genomförd registrering och därmed beställning. Vill du utöva
          ångerrätten ska du skicka ett klart och tydligt meddelande om ditt
          beslut att frånträda avtalet per e-post till support@saluto.ai. Du kan
          använda följande mall: Ångerblankett konsumentverket och skicka till
          oss, men du måste inte använda den utan det räcker med att du i din
          e-post till oss anger att du önskar avbryta prenumerationen med
          information om din beställning (inklusive startdatum, ditt namn och
          orderreferensnummer). För att du ska hinna utöva din ångerrätt i tid
          räcker det med att du sänder in ditt meddelande om att du tänker utöva
          ångerrätten innan ångerfristen gått ut.
        </p>
        <br />
        <p>
          Om tjänsten påbörjats till viss del under ångerfristen återbetalar vi,
          den del av tjänsten som ej har utförts, dock med undantag av vad som
          följer av avsnitt Förverkad ångerrätt nedan, i det fall du vill
          utnyttja din rätt att ångra köpet. Vi kommer att ta ut en
          administrativ avgift när e- remiss blivit skickad i ditt personnummer.
          Denna summa kommer även att dras av från den summa som återbetalas
          till dig i samband med reklamation.
        </p>
        <br />
        <p>
          För undvikande av missförstånd, bestämmelser kring ångerrätt gäller
          inte för dig som användare som har hänvisats till oss av din
          arbetsgivare.
        </p>
        <br />
        <h1 className="text-2xl">
          Verkan av utövad ångerrätt för privatpersoner
        </h1>
        <p>
          Om du utövar din ångerrätt kommer vi att betala tillbaka betalningar
          vi fått från dig. Återbetalningen kommer att ske utan onödigt dröjsmål
          och i vilket fall som helst senast 30 dagar från och med den dag då vi
          underrättades om ditt beslut att frånträda avtalet.
        </p>
        <br />
        <h1 className="text-2xl">Förverkad ångerrätt för privatpersoner</h1>
        <p>
          När blodprovet väl tagits kan inte prenumerationen ångras utan då är
          du hänvisad till att säga upp din prenumeration.
        </p>
        <br />
        <h1 className="text-2xl">
          Remissens giltighetstid för blodprovstagning
        </h1>
        <p>
          Observera att din remiss är giltig i tre månader från och med
          registrering eller från och med när du ombeds att ta ett nytt
          blodprov. Om du inte gått till valt provtagningsställe för blodprov
          inom denna tid passerat upphör remissen att gälla och därefter kommer
          du behöva begära en ny remiss. En påminnelse kommer alltid att skickas
          ut innan remissens giltighetstid löper ut. Du som användare är
          medveten om att en hälsorapport inte kan levereras om blodprov inte
          tas inom remissens giltighetstid. För att du som användare ska kunna
          erhålla hälsorapport och därmed få användning av Salutos tjänster
          förutsätter det att du även lämnar blodprov.
        </p>
        <br />
        <h1 className="text-2xl">
          Säkerhet avseende informationstekniken (IT) samt cookies
        </h1>
        <p>
          Vår underleverantör av journalsystem och databas uppfyller samtliga
          författningsreglerade krav. Journalsystemet är en CE-märkt
          medicinteknisk produkt som uppfyller kraven i det medicintekniska
          regelverket för produkter. Vår underleverantör lagrar journaler enligt
          Patientdatalagen och dessa säkerhetskopieras och görs tillgängliga
          enligt integritetsskyddsmyndighetens riktlinjer.
        </p>
        <br />
        <p>
          En cookie är en textbaserad datafil som en webbserver kan be att få
          spara i webbplatsbesökarens dator. Genom att cookiens innehåll i
          allmänhet skickas tillbaka med varje förfrågan till webbplatsen i
          fråga är det möjligt för servern att hålla reda på besökarens
          preferenser där syftet t.ex. kan vara att användaren inte ska behöva
          logga in på nytt eller ange språkval och andra inställningar inför
          varje besök, att följa med hur webbplatsen används eller att
          användaren ska få individualiserade budskap baserad på tidigare besök,
          sökningar och köp. Cookies kan ha en tidpunkt efter vilken de inte
          längre används, då sparas de (beroende på webbläsarens inställningar)
          på datorns hårddisk, för att kunna returneras också senare (permanent
          cookie). I övriga fall lagras de i datorns primärminne och gäller
          tills webbläsaren stängs ned. Den senare typen kallas session cookies
          eftersom de används för en enskild http-session, inte för att lagra
          information om en kund mellan sessionerna. Cookies används på vår
          hemsida i syfte att samla in anonymiserad statistik via
          webbanalysverktyg samt för att rikta budskap ur ett
          marknadsföringsperspektiv. Dessa cookies kan raderas i webbläsaren,
          och du kan också välja att inte acceptera dem genom att stänga av
          denna möjlighet i din webbläsare.
        </p>
        <br />
        <h1 className="text-2xl">Hantering av personuppgifter</h1>
        <p>
          GDPR trädde i kraft den 25 maj 2018 och ersätter personuppgiftslagen
          (PUL). Förordningen har till syfte att säkerställa en likvärdig nivå
          vad gäller hantering och skydd av personuppgifter i samtliga EU:s
          medlemsländer och gäller som tvingande lag.{" "}
        </p>
        <br />
        <p>
          Vår insamling av personliga data kan kategoriseras som “känsliga
          personuppgifter” inom ramen för GDPR. Vi registrerar olika typer av
          personuppgifter för att kunna genomföra vår leverans av tjänster och
          för att kunna hantera din prenumeration. Exempelvis handlar det om
          personuppgifter kopplade till vården och medicinska data,
          personnummer, postadress, e-postadress, telefonnummer samt valt
          provtagningsställe.
        </p>
        <br />
        <p>
          Det finns olika lagliga grunder till att vi får behandla och lagra
          uppgifter för att kunna erbjuda våra tjänster till dig som användare.
          Vi sparar personuppgifter så länge det finns ett behov, utifrån
          ändamål samt vad olika lagar kräver. För hälsodata, blodprovssvar och
          annan lagrad information gäller att vi sparar dessa via vår
          underleverantör under minst tio år från det att den sista uppgiften
          fördes in i databasen. Personuppgifter och medicinska data kan sparas
          längre tid för arkivändamål samt för forskning eller statistik.
        </p>
        <br />
        <p>
          Om du har hänvisats till oss av din arbetsgivare, kan vi bedömas agera
          personuppgiftsbiträde åt denne och behandlar då dina personuppgifter
          enligt instruktion och på uppdrag av denne. Vi lämnar dock aldrig ut
          några känsliga personuppgifter till din arbetsgivare, dvs uppgifter
          gällande din hälsa inklusive huruvida du har nyttjat Salutos tjänster
          eller inte.
        </p>
        <br />
        <p>
          Du som användare har rätt att begära tillgång till dina sparade
          personuppgifter och även önska rättelse eller radering. Om du vill
          ändra dina kontaktuppgifter, radera uppgifter eller säga upp ditt
          samtycke skickar du en e-post till support@saluto.ai. Vi tar emot din
          förfrågan och tar ställning till om det går att genomföra utifrån
          dataskyddsförordningen och andra lagar.
        </p>
        <br />
        <p>
          I avtal med underleverantör säkerställer vi även att GDPR följs
          avseende hantering av dina personuppgifter. Personuppgifterna sparas
          under den tid man har en pågående avtalsrelation och en tid därefter
          eller i enlighet med lämnat samtycke. Samtycket är giltigt till dess
          att du säger upp det.
        </p>
        <br />
        <h1 className="text-2xl">Hantering av personuppgifter</h1>
        <p>
          Vi följer lagar som behandlar personuppgifter, såsom patientdatalagen
          (2008:355), offentlighetsoch sekretesslagen (2209:400) och hälso- och
          sjukvårdslagen (2017:30) då personuppgifter berörs. Personuppgifterna
          används i syfte att kunna skicka information och påminnelser via sms
          och e-post samt för att kunna genomföra och hantera analyser,
          rapporter, hälsodata och övrig information som tillgängliggörs i
          Saluto genom din användning. E-posten innehåller en bekräftelse på din
          registrering, vilket provtagningsställe du valt, information om vad du
          behöver tänka på inför provtagningen samt dina kunduppgifter och i
          tillämpliga faktureringsadress. Påminnelsen innehåller besked om att
          din hälsorapport är färdig att ta del av i Saluto.
        </p>
        <br />
        <p>
          För närmare information om hur vi behandlar dina personuppgifter,
          vänligen läs även vår Integritetspolicy.
        </p>
        <br />
        <h1 className="text-2xl">Tillgänglig information via Saluto</h1>
        <p>
          När inloggning sker via Saluto hämtas information från en elektronisk
          databas baserat på ditt personnummer. Inloggningen hanteras via BankID
          vilket gör att lösningen är säker för dig som kund.
        </p>
        <br />
        <h1 className="text-2xl">Priser och prisändringar</h1>
        <p>
          Alla priser är angivna i SEK. Priserna för privatpersoner är inklusive
          moms och priserna för företag är exklusive moms. Vi förbehåller oss
          rätten att ändra priserna som anges i Saluto eller på hemsidan.
        </p>
        <br />
        <h1 className="text-2xl">Ansvar för fel</h1>
        <p>
          Vi står inte ansvariga för fel som beror på det laboratorium som
          utfört provtagning och analys eller tekniska fel som beror på antingen
          e-butiksleverantören, journalsystemsleverantören eller en annan tredje
          part.
        </p>
        <br />
        <p>
          Vi är enbart skyldiga att ersätta dig i den mån sådan skyldighet
          följer av tvingande lagstiftning. Vi ersätter inte dig för indirekt
          förlust eller förlust i näringsverksamhet, och vår totala
          ersättningsskyldighet ska inte under några omständigheter överstiga
          vad du har betalat till oss under de 12 månader som föregick den
          skadevållande händelsen.
        </p>
        <br />
        <h1 className="text-2xl">Ändringar av Användarvillkor</h1>
        <p>
          Vi förbehåller oss rätten att ändra Användarvillkoren från tid till
          annan och detta kommer att meddelas via vår hemsida och/eller i
          meddelande till dig. Aktuella Användarvillkor finns alltid tillgänglig
          på vår hemsida.
        </p>
        <br />
        <h1 className="text-2xl">Reklamation</h1>
        <p>
          Kontakta oss på support@saluto.ai om du inte är nöjd med någon del av
          den tjänst vi erbjuder.
        </p>
      </div>
      <FootView pathName={props.location.pathname}></FootView>
    </div>
  );
};

export default UserPolicyPage;
